<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>研究生案例</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <!-- 学校顶部  学校下拉选择分类 -->
        <el-form-item label="学校">
          <el-select
            v-model="query.schID"
            placeholder="请选择学校"
            clearable
            filterable
            @change="selList()"
          >
            <el-option
              v-for="item in schlist"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 学校底部 -->
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <!-- 查询 -->
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
              selectedOptions = [];
            "
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>研究生案例</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
        >
          <el-table-column prop="realName" label="姓名"></el-table-column>
          <el-table-column prop="eduBack" label="学历"></el-table-column>
          <el-table-column prop="schName" label="录取学校"></el-table-column>
          <el-table-column prop="special" label="录取专业"></el-table-column>
          <el-table-column prop="abroadTime" label="留学时间"></el-table-column>
          <el-table-column prop="viewCount" label="浏览次数"></el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="warning"
                    size="mini"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- dialog 操作对话框 -->
    <el-dialog
      :title="form.id > 0 ? '编辑研究生案例' : '添加研究生案例'"
      :visible.sync="dialogVisible"
      @opened="dialogOpened()"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-tabs type="card">
          <el-tab-pane label="基本信息">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="姓名"
                  prop="realName"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                  <el-input
                    v-model="form.realName"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="学历"
                  prop="eduBack"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                <el-select v-model="form.eduBack" placeholder="请选择学历" clearable>
                  <el-option
                  v-for="item in edulist"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                  ></el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="头像" :rules="[{required:true,message:'不能为空'}]">
              <div id="uploadBtn1" class="uploader uploader-warning"></div>
              <img
                id="relativeName"
                :src="domain + form.imgUrl"
                style="height: 40px; position: relative; left: 10px; top: 20px"
                v-if="form.imgUrl"
              />
              <div class="uploader-text">* 代表图尺寸：推荐200px*200px</div>
            </el-form-item>

            <!-- 学历 -->
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="录取学校"
                  prop="schID"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                  <el-select
                    v-model="form.schID"
                    placeholder="请选择录取学校"
                    clearable
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in schlist"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="录取专业" prop="special">
                <el-select v-model="form.special" placeholder="请选择大学专业" clearable filterable style="width:100%;">
                  <el-option
                  v-for="item in majorlist"
                  :key="item.id"
                  :value="item.title"
                  :label="item.title"
                  ></el-option>
                </el-select>
                  <!-- <el-input
                    v-model="form.special"
                    placeholder="请输入录取专业"
                  ></el-input> -->
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item prop="abroadTime" label="留学时间">
                  <el-date-picker
                    v-model="form.abroadTime"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    type="month"
                    placeholder="选择留学时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="院校" prop="fromSchool">
                  <el-input
                    v-model="form.fromSchool"
                    placeholder="请输入院校"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="语言成绩" prop="language">
                  <el-input
                    v-model="form.language"
                    placeholder="请输入语言成绩"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane label="详细内容">
            <el-form-item label="详情介绍" prop="content">
              <vue-ueditor-wrap
                v-model="form.content"
                :config="editorConfig"
              ></vue-ueditor-wrap>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: { VueUeditorWrap },
  data() {
    return {
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },
      domain: url.getDomain(),
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      schlist: [], //录取学校
      edulist:[],//学历列表
      majorlist:[],//专业
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    // 获取所有大学专业
    getAllMajor(){
      var _this=this;
      var link=url.getStuCase('GetAllMajor');
      $.get(link,{},data=>{
        _this.majorlist=data;
      })
    },
    //留学时间
    getTimeList() {
      var yearList = [];
      var year = new Date().getFullYear() + 3;
      var monthList = this.getMonth();
      for (var i = year; i > year - 10; i--) {
        yearList.push({
          id: i,
          value: i,
          children: monthList,
        });
      }
      this.timelist = yearList;
    },
    // 获取所有大学
    getAllSchoolList: function () {
      var _this = this;
      var link = url.getSchool("GetAllCollege");
      $.get(link, {}, (data) => {
        _this.schlist = data;
      });
    },
    // 获取学历
    getEduBackList(){
      var _this=this;
      var link=url.getStuCaseCollege('GetEduBack');
      $.get(link,{},data=>{
        _this.edulist=data;
      })
    },
    // 查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      var link = url.getStuCaseCollege("GetList_StuCaseCollege");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询输入框     函数定义
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //编辑
    editClick: function (row) {
      var _this = this;
      var id = row.id;
      var link = url.getStuCaseCollege("GetModel_StuCaseCollege");
      $.get(link, { id: id }, (res) => {
        _this.form = res;
        _this.dialogVisible = true;
      });
    },
    //上传图片
    dialogOpened: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn1 input").length == 0) {
        $("#uploadBtn1").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          type: "dialog",
          coverParams: {
            targetWidth: 200,
            targetHeight: 200,
            // itemWidth: 700,
            // itemheight:500,
          },
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });
      }
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getStuCaseCollege("Save_StuCaseCollege");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var id = row.id;
      var link = url.getStuCaseCollege("Delete_StuCaseCollege"); //获取请求地址

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    this.getAllMajor();
    // 获取学历
    this.getEduBackList();
    // 获取所有的大学
    this.getAllSchoolList();
    //获取列表
    this.getList();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
